#sobre{
    width: 100%;
    .wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        .aboutText{
            width: 90%;
            p{
                font-family: Arial;
                font-size: 1.38vw;
                text-align: center;
                margin: 0;
            }
        }
        .CAVR{
            padding-top: 2vw;
            width: 90%;
            display: flex;
            flex-direction: row;
            .logo{
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: start;
                align-items: center;
                img{
                    width: 38dvw;
                }                
            }
            .texts{
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                h2{
                    width: 100%;
                    font-family: 'Arial Rounded MT Bold';
                    color: #B5265D;
                    font-size: 2vw;
                    text-align: center;
                    margin-bottom: 5px;
                }
                p{
                    width: 100%;
                    font-family: Arial;
                    font-size: 1.38vw;
                    text-align: center;
                }	
            }
        }
        .infosCAVR{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            padding-bottom: 1vh;
            .infos{
                width: 90%;
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: start;
                .card{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: center;
                    margin-inline: 1vw;
                    h3{
                        font-family: 'Arial Rounded MT Bold';
                        color: #b62f5e;
                        font-size: 2.25vw;
                        text-align: center;
                        margin-bottom: 5px;
                    }
                    p{
                        font-family: Arial;
                        font-size: 1.5vw;
                        text-align: center;
                    }
                }
            }
            
        }
        .CERAV{
            padding-top: 1vw;
            width: 90%;
            display: flex;
            flex-direction: row;
            .logo{
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: end;
                align-items: center;
                img{
                    width: 38dvw;
                }                
            }
            .texts{
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                h2{
                    width: 100%;
                    font-family: 'Arial Rounded MT Bold';
                    color: #0E284E;
                    font-size: 2vw;
                    text-align: center;
                    margin-bottom: 5px;
                }
                p{
                    width: 100%;
                    font-family: Arial;
                    font-size: 1.38vw;
                    text-align: center;
                }	
            }
        }
        .infosCERAV{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            padding-bottom: 1vh;
            .infos{
                width: 90%;
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: start;
                .card{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: center;
                    margin-inline: 1vw;
                    h3{
                        font-family: 'Arial Rounded MT Bold';
                        color: #0E284E;
                        font-size: 2.25vw;
                        text-align: center;
                        margin-bottom: 5px;
                    }
                    p{
                        font-family: Arial;
                        font-size: 1.5vw;
                        text-align: center;
                    }
                }
            }
        }
    }
}

/* Query for mobile */
@media (max-width: 768px){
    #sobre{
        .wrapper{
            .aboutText{
                width: 90%;
                p{
                    font-size: 2.3dvh;
                }
            }
            .CAVR{
                flex-direction: column-reverse;
                .logo{
                    padding-block: 6vh !important;
                    width: 100%;
                    justify-content: center !important;
                    img{
                        width: 75vw;
                        padding-left: 25vw;
                        padding-right: 28vw;
                    }
                }
                .texts{
                    width: 100%;
                    h2{
                        font-size: 3dvh;
                    }
                    p{
                        font-size: 2.3dvh;
                    }
                }
            }
            .infosCAVR{
                .infos{
                    flex-direction: column;
                    .card{
                        width: 100% !important;
                        h3{
                            font-size: 3.5vh;
                        }
                        p{
                            font-size: 2.3vh;
                        }
                    }
                }
            }
            .CERAV{
                flex-direction: column;
                .logo{
                    padding-block: 6vh !important;
                    width: 100%;
                    justify-content: center !important;
                    img{
                        width: 75vw;
                        padding-left: 25vw;
                        padding-right: 28vw;
                    }
                }
                .texts{
                    width: 100% !important;
                    h2{
                        font-size: 6vw;
                    }
                    p{
                        font-size: 2.3dvh;
                    }
                }
            }
            .infosCERAV{
                .infos{
                    flex-direction: column;
                    .card{
                        width: 100% !important;
                        h3{
                            font-size: 3.5vh;
                        }
                        p{
                            font-size: 2.3vh;
                        }
                    }
                }
            }
        }
    }
}
