section.inicio{
    justify-content: space-between !important;
    .slogan{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 10dvh;
        padding-left: 4vw;
        h1{
            font-size: clamp(2rem, 2.5vw, 3rem) !important;
            color: #BB3A67;
            font-family: 'Arial Rounded MT Bold';
            text-align: center;
            margin: 0;
            padding-bottom: 7vh;
        }
        button{
            padding: 1.5vh 3vw;
            font-size: 1.5rem;
            font-weight: bold;
            color: white;
            background-color: #BB3A67;
            border: none;
            border-radius: 50px;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover{
                background-color: #A52B5B;
            }
        }
    }
    img{
        padding-block: 3dvh;
        width: 85dvh;
        height: 85dvh;
        object-fit: contain;
        filter: opacity(1);
        filter:brightness(1.02);
        filter:contrast(1.02);
    }
}

/* Query for mobile */
@media (max-width: 768px){
    #inicio{
        min-height: 90dvh !important;
        flex-direction: column-reverse !important;
        justify-content: start !important;
        align-items: center;
        img{
            padding: 10vw 0 10vw 0;
            margin: 0;
            width: 90%;
            height: 90%;
            border-radius: 20%;
        }
        .slogan{
            padding:0;
            margin:0;
            h1{
                padding-bottom: 3vh;
            }
            button{
                margin-top: 2vh;
                padding: 1vh 2vh;
                font-size: 2.5vh;
                &:active{
                    transform: scale(0.95);
                }
            }
        }
    }
}
