.separator{
    width: 100%;
    height: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    hr{
        width: 25dvw;
        border: 0;
        border-top: 2px solid rgba(0,0,0,0.3);
    }
}