@font-face {
  font-family: 'Arial Rounded MT Bold';
  src: url('./fonts/ArialRoundedMTBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Arial Rounded MT';
  src: url('./fonts/ArialRoundedMT.ttf') format('truetype');
}
*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

body, #root, #root>.container {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root>.container>section{
  display: flex;
  flex-direction: row;
  min-height: 100dvh;
  /* border-bottom: 1px solid #000; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
width: 0;
}

/* Text styles */
h1, h2, h3, h4, h5, h6, p, a, span, button, input, label, select, option, textarea{
  font-smooth: antialiased;
}
img{
  image-rendering: auto;
}

#whitespace{
  height: 300vw;
}

h1.decTitle{
  font-family: 'Arial Rounded MT Bold';
  font-size: 4dvh;
  padding-bottom: 5px;
  border-bottom: 5px solid #bb3a67;
  border-radius: 3px;
}

/* Query for mobile */
@media (max-width: 768px) {
  h1.decTitle{
    font-size: 3dvh;
    padding-bottom: 5px;
    border-bottom: 5px solid #bb3a67;
    border-radius: 3px;
  }
}
