#ajude{
    width: 100%;
    margin-bottom: -5vw;
    .wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        .insideWrapper{
            width: 100%;
            margin-top: -1.5vw;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: start;
            .women{
                margin-top: -0.2vw;
                display: flex;
                justify-content: center;
                width: 10%;
                img{
                    width: 5vw;
                    height: auto;
                }
            }
            .texts{
                width: 80%;
                padding-right: 10%;
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                h3{
                    width: 100%;
                    text-align: center;
                    /* border: 1px solid black; */
                    font-family:'Arial Rounded MT Bold';
                    font-size: 1.6vw;
                }
                .wrapBlocks{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    align-items: start;
                    margin-top: -1vw;
                    .block1{
                        width: 45%;
                        margin-left: 5%;
                        margin-right: 0%;
                        h3{
                            text-align: start;
                        }
                        p{
                            font-family: Arial;
                            font-size: 1.5vw;
                        }
                        div{
                            font-family: Arial;
                            font-size: 1.5vw;
                        }
                        #pixClipboard{
                            display: inline;
                            
                            svg{
                                width: 2vw;
                                margin-inline: 10px;
                                margin-bottom: -0.3vw;
                            }
                            svg:hover{
                                cursor: pointer;
                            }
                            svg:active{
                                transform: scale(0.9);
                            }
                        }
                        #notifier{
                            display: inline;
                            font-size: 1vw;
                            text-transform: uppercase;
                            font-family: "Arial Rounded MT Bold";
                            color: #bb3a67;
                            margin-inline: 10px;
                            /* Animation */
                            opacity: 0;
                            transition: opacity 0.25s;
                        }
                    }
                    .block2{
                        width: 45%;
                        margin-left: 5%;
                        margin-right: 0%;
                        h3{
                            text-align: start;
                        }
                        p{
                            font-family: Arial;
                            font-size: 1.5vw;
                        }
                        div{
                            font-family: Arial;
                            font-size: 1.5vw;
                            a{
                                text-decoration: none;
                                color: #bb3a67;
                                font-family: "Arial Rounded MT Bold";
                            }
                        }
                        img{
                            height: 10vw;
                            margin-top: 4.2vw;
                        }
                    }
                }
            }
        }
    }
}

/* Query for mobile */
@media (max-width: 768px){
    #ajude{
        margin-bottom: 2.5vh;
        .wrapper{
            .insideWrapper{
                justify-content: center;
                .women{
                    display: none;
                }
                margin-top: -3vh;
                .texts{
                    flex-direction: column-reverse;
                    width: 90%;
                    padding: 0;
                    h3{
                        order: 1;
                        font-size: 2.75vh;
                        margin-bottom: 1vh;
                    }
                    .wrapBlocks{
                        flex-direction: column;
                        .block1{
                            margin:0;
                            width: 100%;
                            h3{
                                font-size: 2.3vh;
                            }
                            p{
                                font-size: 2vh;
                            
                            }
                            div{
                                font-size: 2vh;
                                #pixClipboard{
                                    svg{
                                        width: 2.5vh;
                                        margin-inline: 10px;
                                        margin-bottom: -0.4vh;
                                    }
                                }
                                #notifier{
                                    position: fixed;
                                    bottom: 1.5vh;
                                    left: 0;
                                    background-color: rgba(187, 58, 103, 0.95);
                                    color: white;
                                    font-size: 2vh;
                                    text-align: center;
                                    padding: 1.5vh;
                                    width: auto;
                                    border-radius: 50px;
                                    /* opacity: 1 !important; */
                                }
                            }
                        }
                        .block2{
                            margin:0;
                            width: 100%;
                            h3{
                                font-size: 2.3vh;
                            }
                            p{
                                font-size: 2vh;
                            }
                            img{
                                height: 28vw;
                                margin: 3vh 0 1.5vh 0;
                            }
                            div{
                                font-size: 2vh;
                                
                            }
                        }
                    }
                }
            }
        }
    }
}