#contato{
    width: 100%;
    min-height: 90dvh !important;
    .wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        .textblock1{
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            div{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 1.5vw;
                .phoneNumber{
                    margin-left: 10px;
                    font-size: 1.95vw;
                    font-weight: bold;
                    color: #B5265D;
                    text-decoration: none;
                }
                .mailLink{
                    margin-inline: 10px;
                    font-size: 1.5vw;
                    font-weight: bold;
                    color: #B5265D;
                    text-decoration: none;
                }
            }
            .desc{
                margin-top: 20px;
                font-size: 1.3vw;
            }
        }
        .textblock2{
            margin-top: 1vw;
            width: 80%;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: start;
            .formblock{
                width: 60%;
            }
            .decorationblock{
                width: 40%;
                img{
                    width: 100%;
                }
            }
        }
    }
}

.formblock{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    .formrow{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        .formgroup-half{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            text-transform: uppercase;
            label{
                font-size: 1.5vw;
                font-family: 'Arial Rounded MT';
            }
            input{
                width: 90%;
                margin-right: 5%;
                height: 2vw;
                font-size: 1.5vw;
                margin-top: 5px;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                &:focus{
                    outline: none !important;
                    border: 1px solid #000;
                    box-shadow: 0 0 3px #000;
                }
            }
        }
        .formgroup{
            width: 95%;
            margin-right: 5%;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            label{
                font-size: 1.5vw;
                font-family: 'Arial Rounded MT';
                text-transform: uppercase;
            }
            input{
                width: 100%;
                height: 2vw;
                font-size: 1.5vw;
                margin-top: 5px;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                &:focus{
                    outline: none !important;
                    border: 1px solid #000;
                    box-shadow: 0 0 3px #000;
                }
            }
            textarea{
                resize: none;
                width: 100%;
                height: 10vw;
                font-size: 1vw;
                margin-top: 5px;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                &:focus{
                    outline: none !important;
                    border: 1px solid #000;
                    box-shadow: 0 0 3px #000;
                }
            }
        }
        button{
            width: 20%;
            height: 3vw;
            margin-top: 10px;
            font-size: 1.5vw;
            font-family: 'Arial Rounded MT Bold';
            color: #fff;
            background-color: #B5265D;
            border: none;
            border-radius: 50px;
            cursor: pointer;
            &:hover{
                background-color: #8C1A45;
            }
        }
    }
}

/* Query for mobile */
@media (max-width: 768px){
    #contato{
        height: auto !important;
        .wrapper{
            .textblock1{
                flex-direction: column;
                width: 95%;
                justify-content: center;
                align-items: center;
                text-align: center;
                div{
                    display: inline;
                    font-size: 2vh;
                    .phoneNumber{
                        margin: 0 !important;
                        font-size: 2vh;
                    }
                }
                .desc{
                    font-size: 2vh;
                    .mailLink{
                        margin: 0 !important;
                        font-size: 2vh;
                    }
                }
            }
            .textblock2{
                margin-top: 3vh;
                width: 90%;
                .formblock{
                    width: 100%;
                    .formrow{
                        width: 100%;
                        margin-top: 0;
                        flex-direction: column;
                        justify-content: center;                        
                        .formgroup-half{
                            margin-bottom: 0;
                            width: 100%;
                            label{
                                font-size: 4vw;
                                margin-bottom: 5px;
                            }
                            input{
                                margin:0;
                                margin-bottom: 10px;
                                width: 100%;
                                height: 8vw;
                                font-size: 3vw;
                            }
                        }
                        .formgroup{
                            justify-content: center;
                            width: 100% !important;
                            margin-right: 0 !important;
                            label{
                                font-size: 4vw;
                                margin-bottom: 5px;
                            }
                            input{
                                width: 100%;
                                height: 8vw;
                                margin-bottom: 10px;
                                font-size: 3vw;
                            }
                            textarea{
                                width: 100%;
                                height: 25vw;
                                font-size: 2.5vw;
                            }
                        }
                        button{
                            width: 30%;
                            height: auto;
                            font-size: 2.5vh;
                            padding: 1vh 2vh;
                            margin-top: 3vh;
                            &:active{
                                transform: scale(0.95);
                            }
                        }
                    }
                }
                .decorationblock{
                    display: none;
                }
            }
        }
    }
}
