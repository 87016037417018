footer{
    background-color: #fff;
    color: #000;
    width: 100%;
    height: 60px;
    box-shadow: 0px 3px 20px 5px rgba(0,0,0,0.3);
    z-index: 1;
    .wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        p{
           margin-left: 2vw;
        }
        .right{
            margin-right: 6vw;
            a{
                margin-inline: 1vw;
            }
            a:hover{
                cursor: pointer;
            }
        }
    }
}

/* Query for mobile */
@media (max-width: 768px){
    footer{
        height: 8vh;
        .wrapper{
            flex-direction: column;
            justify-content: center;
            align-items: start;
            margin-left: 2.5vw;
            p{
                padding: 0;
                margin: 0;
                font-size: 1.5vh;
            }
            .right{
                margin: 0;
                margin-top: 1vh;
                a{
                    margin: 0;
                    margin-right: 10px;
                    font-size: 1.5vh;
                }
            }
        }
    }
}
