.navbar{
    position: sticky;
    top: 0;
    width: 100%;
    height: 9dvh;
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.05);
    background-color: rgba(255,255,255,0.95);
    z-index: 100;
}

.navbar>.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 5dvw;
    height: 100%;
}

.navbar>.container>img{
    height: 7dvh;
    width: auto;
    cursor: pointer;
}
.navbar .rightSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: auto;

}
.navbar .navButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: auto;
    margin: 0 1vw;
    a{
        margin: 0 20px;
        font-size: clamp(1.2rem, 1.5vw, 1.5rem);
        color: #000;
        text-decoration: none;
        text-transform: uppercase;
        font-family: 'Arial Rounded MT';
        cursor: pointer;
    }
    a.active{
        margin-bottom: -9px;
        padding-bottom: 5px;
        border-bottom: #B5265D 4px solid;
        border-spacing: 10px 10px;
        border-radius: 3px;
    }

}
.navbar .socialMedia{
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    width: auto;
    margin-left: 20px;
    a{
        margin: 0 10px;
    }
}

/* Query for mobile */
@media (max-width: 768px){
    .navbar{
        height: auto;
    }
    .navbar>.container{
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
    .navbar>.container>img{
        height: 6dvh;
        padding-block: 1dvh;
        &:active{
            transform: scale(0.85);
        }
    }
    .navbar .rightSection{
        border-top: 1px solid rgba(0,0,0,0.3);
        width: 100%;
        align-items: end;
        flex-direction: column-reverse;
        margin-left: 10px;
    }
    .navbar .navButtons{
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-block: 5px;
        a{
            margin: 0px 6px;
            font-size: clamp(1vw, 3.5vw, 5vw);
            &:active{
                transform: scale(0.85);
            }
        }
    }
    .navbar .socialMedia{
        position: absolute;
        right: 0;
        top: 0;
        padding: 2.5dvh 1.5dvw;

        a{
            margin:0;
            margin-right: 10px;
            img{
                height: 3dvh;
            }
            &:active{
                transform: scale(0.85);
            }
        }
    }
}
