#transparencia{
    height: 95%;
    width: 100%;
    .container{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        margin:0;
        .body{
            height: 100%;
            width: 100%;
            margin:0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            #backButton{
                position: absolute;
                top: 0;
                left: 0;
                height: 5vh;
                width: 5vh;
                margin: 1vh;
                background-image: url('../imgs/FloatBtn.png');
                background-size: cover;
                background-repeat: no-repeat;
                rotate: 270deg;
                &:hover{
                    cursor: pointer;                
                }
            }
            #navTransp{
                height: 8vh;
                width: 80%;
                margin-left: 5vh;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                border-bottom: 1px solid rgba(0,0,0,0.3);
                margin-bottom: 1vh;
                a{
                    text-decoration: none;
                    color: #bb3a67;
                    font-size: 2vh;
                    font-weight: bold;
                    transition: 0.5s;
                    &:hover{
                        cursor: pointer;
                        color: #e1457c;
                        transform: scale(1.1);
                    }
                }
            }
            #transparenciaContent1{
                display: show;
                opacity: 0;
                transition: opacity 0.5s;
                width: 100%;
                height: 100%;
                iframe{
                    border: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            #transparenciaContent2{
                display: none;
                opacity: 0;
                transition: opacity 0.5s;
                width: 100%;
                height: 100%;
                iframe{
                    border: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}