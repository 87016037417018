.floatingButton{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    background-color: #bb3a67;
    background-image: url('../imgs/FloatBtn.png');
    background-size: cover;
    color: #fff;
    height: 2.5vw;
    width: 2.5vw;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 0 0 10px rgba(0,0,0,0.5); 
  }
  .floatingButton:active{
    transform: scale(0.8);
  }

  /* Query for mobile */
    @media (max-width: 768px){
        .floatingButton{
          right: 15px;
          bottom: 15px;
          height: 7vw;
          width: 7vw;
        }
    }